@import url('fonts.css');



body {
    background: #FF0068;
}

.top-title-block {
    padding: 140px 0px 30px;
    text-align: center;
}

.connect {
    min-width: 380px;
    height: 120px;
    padding: 45px 10px 0px;
}

.t-img {
    width: 100%;
    max-width: 910px;
    margin: 0 auto;
}

.t-img img,
.t-img2 img {
    width: 100%;
}

.t-img2 {
    max-width: 544px;
    width: 100%;
    margin: 45px auto 0px;
}

.mint-block {
    text-align: center;
}

.mint-block h3 {
    font-size: 128px;
    color: #FFFFFF;
    line-height: 106px;
    font-family: Handjet-Flower-Double-Medium;
}

.mint-block h5 {
    font-size: 64px;
    /* line-height: 72px; */
    color: #FFFFFF;
    font-family: Handjet-Flower-Double-Medium;
}


.mint-block h6 {
    font-size: 34px;
    /* line-height: 72px; */
    color: #FFFFFF;
    font-family: Handjet-Flower-Double-Medium;
}


.minted-wrap {
    padding: 70px 0px 80px;
    background: #ff0068 url('../images/bg-line.png') repeat-x;
    background-blend-mode: darken;
}

.m-btns {
    margin-top: 20px;
}

.m-btns button {
    min-width: 380px;
    height: 120px;
    background: url('../images/button-bg.png') no-repeat center;
    background-size: 100%;
    border: 0px;
    font-size: 48px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Handjet-Flower-Double-Medium;
    margin: 0px 30px;
}

.help-wrap {
    padding: 113px 0px 0px;
}

.help-title {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.help-title h5 {
    font-size: 64px;
    font-family: Handjet-Flower-Double-Medium;
    color: #FEE301;
}

.c-img-block {
    /* width: 230px;*/
    height: 230px;
    margin: 0 auto;
}

.c-img-block img {
    max-width: 230px;
    max-height: 230px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.hc-block h3 {
    font-size: 64px;
    color: #FF0068;
    font-family: Handjet-Flower-Double-Medium;
}

.hc-block p {
    font-size: 32px;
    color: #FF0068;
    font-family: Handjet-Flower-Double-Medium;
}

.help-text {
    padding: 100px 0px;
    background: #FEE301;
    text-align: center;
}

.cat-img-block img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.gall-row {
    display: grid;
    height: 5px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 0px;
}

.t-icon {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 75px;
    height: auto;
    background: transparent;
    border: 0px;
    padding: 0px;
}

.t-icon img {
    width: 100%;
    height: auto;
}

@media (max-width: 1024px) {
    .mint-block h3 {
        font-size: 58px;
        line-height: normal;
    }

    .c-btn button {
        font-size: 5x;
        min-width: auto;
        padding: 5px 20px;
        height: 50px;
        margin: 0 10px;
    }

    .mint-block h5 {
        font-size: 44px;
        line-height: normal;
    }

    .m-btns button {
        font-size: 20px;
        min-width: auto;
        padding: 5px 20px;
        height: 50px;
        margin: 0 10px;
    }

    .wc-btn button {
        font-size: 20px;
        min-width: auto;
        padding: 5px 20px;
        height: 50px;
        margin: 0 10px;
    }

    .help-title h5 {
        font-size: 22px;
    }

    .top-title-block {
        padding: 30px 15px;
    }

    .minted-wrap {
        padding: 30px 0px;
    }

    .help-wrap {
        padding: 50px 0px 0px;
    }

    .hc-block h3 {
        font-size: 34px;
    }

    .hc-block p {
        font-size: 20px;
    }

    .help-text {
        padding: 50px 0px;
        /* margin-top: -3px; */
    }

    .gall-row {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .c-img-block {
        height: auto;
    }

    .t-icon {
        top: 5px;
        right: 11px;
        width: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .top-title-block {
        padding: 84px 15px 20px;
    }

    .t-icon {
        width: 55px;
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 1024px) {
    .t-img2 {
        max-width: 434px;
    }

    .t-img {
        max-width: 650px;
    }
}

@media (max-width: 767px) {
    .t-img2 {
        max-width: 154px;
    }

    .t-img {
        max-width: 230px;
    }

    .help-xs-row {
        display: flex;
    }

    .help-title {
        flex-direction: column;
        max-width: 50%;
        flex: 0 50%;
    }
    .cat-img-row {
        max-width: 50%;
        flex: 0 50%;
    }
    .cat-img-row .row {
        flex-direction: column;
    }

    .cat-img-row .row-cols-5>* {
        width: 100%;
    }

    .c-img-block img {
        max-width: 115px;
        max-height: 116px;
    }
    .help-title h5{
        font-size: 64px;    
    }
    .c-img-block {
        height: auto;
        margin: 15px 0px;
    }
    .cat-img-row .row .col:last-child .c-img-block{
        margin-bottom: 0px;
    }
}