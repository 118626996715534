@font-face {
    font-family: HandjetCircle-Bold;
    src: url('../fonts/HandjetCircle-Bold.ttf');
}

@font-face {
    font-family: Handjet-Regular;
    src: url('../fonts/Handjet-Regular.ttf');
}

@font-face {
    font-family: Handjet-Flower-Double-Medium;
    src: url('../fonts/Handjet-Flower-Double-Medium.ttf');
}